import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import TheHotelSearchForm from './hotel-search/TheHotelSearchForm.vue';
import { set } from 'lodash';
Vue.use(VueCompositionApi);
Vue.config.productionTip = false;
export const vApp = new Vue({
    el: `#hotel-search`,
    components: {
        'hotel-search-form': TheHotelSearchForm,
    },
});
set(window, `$$AppHotelSearch`, vApp);
