var _a, _b, _c, _d, _e;
import { foundPath } from '~/libs/eyes-lib/basic';
const ENV = {
    BASE_URL_HOTEL: (_a = foundPath(window, `ENVIRONMENT.BASE_URL_HOTEL_TEST`, `ENVIRONMENT.BASE_URL_HOTEL_STAGING`, `ENVIRONMENT.BASE_URL_HOTEL_PRODUCTION`)) !== null && _a !== void 0 ? _a : SET_BASE_URL_HOTEL,
    CONTEXT_PATH: (_b = foundPath(window, `ENVIRONMENT.CONTEXT_PATH_TEST`, `ENVIRONMENT.CONTEXT_PATH_STAGING`, `ENVIRONMENT.CONTEXT_PATH_PRODUCTION`)) !== null && _b !== void 0 ? _b : SET_CONTEXT_PATH,
    API_HOTEL: (_c = foundPath(window, `ENVIRONMENT.API_HOTEL_TEST`, `ENVIRONMENT.API_HOTEL_STAGING`, `ENVIRONMENT.API_HOTEL_PRODUCTION`)) !== null && _c !== void 0 ? _c : SET_API_HOTEL,
    API_GALLERY: (_d = foundPath(window, `ENVIRONMENT.API_GALLERY_TEST`, `ENVIRONMENT.API_GALLERY_STAGING`, `ENVIRONMENT.API_GALLERY_PRODUCTION`)) !== null && _d !== void 0 ? _d : SET_API_GALLERY,
    API_MYPAGE: (_e = foundPath(window, `ENVIRONMENT.API_MYPAGE_TEST`, `ENVIRONMENT.API_MYPAGE_STAGING`, `ENVIRONMENT.API_MYPAGE_PRODUCTION`)) !== null && _e !== void 0 ? _e : SET_API_MYPAGE
};
const plugin = (context, inject) => {
    inject(`ENV`, ENV);
};
export default plugin;
export { ENV };
