const GET = 'GET';
const POST = 'POST';
const PUT = 'PUT';
const DELETE = 'DELETE';
const PATH0 = '/api/accessspot';
const PATH1 = '/api/accessspot/del';
const PATH2 = '/api/accessspot/ins';
const PATH3 = '/api/accessspot/list';
const PATH4 = '/api/accessspot/merge';
const PATH5 = '/api/accessspot/upd';
const PATH6 = '/api/hotelgroup';
const PATH7 = '/api/hotelgroup/del';
const PATH8 = '/api/hotelgroup/ins';
const PATH9 = '/api/hotelgroup/list';
const PATH10 = '/api/hotelgroup/upd';
const PATH11 = '/api/hotelgroupgrant';
const PATH12 = '/api/hotelgroupgrant/del';
const PATH13 = '/api/hotelgroupgrant/ins';
const PATH14 = '/api/hotelgroupgrant/list';
const PATH15 = '/api/hotelgroupgrant/login';
const PATH16 = '/api/hotelgroupgrant/upd';
const PATH17 = '/api/hotelgroupimage';
const PATH18 = '/api/hotelgroupimage/del';
const PATH19 = '/api/hotelgroupimage/file';
const PATH20 = '/api/hotelgroupimage/ins';
const PATH21 = '/api/hotelgroupimage/list';
const PATH22 = '/api/hotelgroupimage/upd';
const PATH23 = '/api/hotelgroupinfo';
const PATH24 = '/api/hotelgroupinfo/del';
const PATH25 = '/api/hotelgroupinfo/ins';
const PATH26 = '/api/hotelgroupinfo/list';
const PATH27 = '/api/hotelgroupinfo/upd';
const PATH28 = '/api/hotelgroupplan/list';
const PATH29 = '/api/login/charger';
const PATH30 = '/api/onetimecharger';
const PATH31 = '/api/onetimelogin';
const PATH32 = '/api/onetimeloginc';
const PATH33 = '/api/onetimelogink';
const PATH34 = '/api/onetimemail';
const PATH35 = '/api/onetimename';
const PATH36 = '/api/onetimepass';
const PATH37 = '/api/onetimepasscust';
const PATH38 = '/api/onetimepassforgety';
const PATH39 = '/api/onetimesendaccesskey';
const PATH40 = '/api/onetimesendhg';
const PATH41 = '/api/roomclassinfo';
const PATH42 = '/api/roomclassinfo/del';
const PATH43 = '/api/roomclassinfo/ins';
const PATH44 = '/api/roomclassinfo/list';
const PATH45 = '/api/roomclassinfo/upd';
const PATH46 = '/api/test';
const PATH47 = '/api/toursearch/list';
const api = ({ baseURL, fetch }) => {
    const prefix = (baseURL === undefined ? '/big/hotelmanage/v1' : baseURL).replace(/\/$/, '');
    return {
        api: {
            accessspot: {
                del: {
                    delete: (option) => fetch(prefix, PATH1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, PATH1, DELETE, option).send().then(r => r.body)
                },
                ins: {
                    put: (option) => fetch(prefix, PATH2, PUT, option).json(),
                    $put: (option) => fetch(prefix, PATH2, PUT, option).json().then(r => r.body)
                },
                list: {
                    post: (option) => fetch(prefix, PATH3, POST, option).json(),
                    $post: (option) => fetch(prefix, PATH3, POST, option).json().then(r => r.body)
                },
                merge: {
                    post: (option) => fetch(prefix, PATH4, POST, option).send(),
                    $post: (option) => fetch(prefix, PATH4, POST, option).send().then(r => r.body)
                },
                upd: {
                    post: (option) => fetch(prefix, PATH5, POST, option).send(),
                    $post: (option) => fetch(prefix, PATH5, POST, option).send().then(r => r.body)
                },
                post: (option) => fetch(prefix, PATH0, POST, option).json(),
                $post: (option) => fetch(prefix, PATH0, POST, option).json().then(r => r.body)
            },
            hotelgroup: {
                del: {
                    delete: (option) => fetch(prefix, PATH7, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, PATH7, DELETE, option).send().then(r => r.body)
                },
                ins: {
                    put: (option) => fetch(prefix, PATH8, PUT, option).json(),
                    $put: (option) => fetch(prefix, PATH8, PUT, option).json().then(r => r.body)
                },
                list: {
                    post: (option) => fetch(prefix, PATH9, POST, option).json(),
                    $post: (option) => fetch(prefix, PATH9, POST, option).json().then(r => r.body)
                },
                upd: {
                    post: (option) => fetch(prefix, PATH10, POST, option).send(),
                    $post: (option) => fetch(prefix, PATH10, POST, option).send().then(r => r.body)
                },
                post: (option) => fetch(prefix, PATH6, POST, option).json(),
                $post: (option) => fetch(prefix, PATH6, POST, option).json().then(r => r.body)
            },
            hotelgroupgrant: {
                del: {
                    delete: (option) => fetch(prefix, PATH12, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, PATH12, DELETE, option).send().then(r => r.body)
                },
                ins: {
                    put: (option) => fetch(prefix, PATH13, PUT, option).send(),
                    $put: (option) => fetch(prefix, PATH13, PUT, option).send().then(r => r.body)
                },
                list: {
                    post: (option) => fetch(prefix, PATH14, POST, option).json(),
                    $post: (option) => fetch(prefix, PATH14, POST, option).json().then(r => r.body)
                },
                login: {
                    post: (option) => fetch(prefix, PATH15, POST, option).json(),
                    $post: (option) => fetch(prefix, PATH15, POST, option).json().then(r => r.body)
                },
                upd: {
                    post: (option) => fetch(prefix, PATH16, POST, option).send(),
                    $post: (option) => fetch(prefix, PATH16, POST, option).send().then(r => r.body)
                },
                post: (option) => fetch(prefix, PATH11, POST, option).json(),
                $post: (option) => fetch(prefix, PATH11, POST, option).json().then(r => r.body)
            },
            hotelgroupimage: {
                del: {
                    delete: (option) => fetch(prefix, PATH18, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, PATH18, DELETE, option).send().then(r => r.body)
                },
                file: {
                    post: (option) => fetch(prefix, PATH19, POST, option, 'FormData').send(),
                    $post: (option) => fetch(prefix, PATH19, POST, option, 'FormData').send().then(r => r.body)
                },
                ins: {
                    put: (option) => fetch(prefix, PATH20, PUT, option).json(),
                    $put: (option) => fetch(prefix, PATH20, PUT, option).json().then(r => r.body)
                },
                list: {
                    post: (option) => fetch(prefix, PATH21, POST, option).json(),
                    $post: (option) => fetch(prefix, PATH21, POST, option).json().then(r => r.body)
                },
                upd: {
                    post: (option) => fetch(prefix, PATH22, POST, option).send(),
                    $post: (option) => fetch(prefix, PATH22, POST, option).send().then(r => r.body)
                },
                post: (option) => fetch(prefix, PATH17, POST, option).json(),
                $post: (option) => fetch(prefix, PATH17, POST, option).json().then(r => r.body)
            },
            hotelgroupinfo: {
                del: {
                    delete: (option) => fetch(prefix, PATH24, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, PATH24, DELETE, option).send().then(r => r.body)
                },
                ins: {
                    put: (option) => fetch(prefix, PATH25, PUT, option).json(),
                    $put: (option) => fetch(prefix, PATH25, PUT, option).json().then(r => r.body)
                },
                list: {
                    post: (option) => fetch(prefix, PATH26, POST, option).json(),
                    $post: (option) => fetch(prefix, PATH26, POST, option).json().then(r => r.body)
                },
                upd: {
                    post: (option) => fetch(prefix, PATH27, POST, option).send(),
                    $post: (option) => fetch(prefix, PATH27, POST, option).send().then(r => r.body)
                },
                post: (option) => fetch(prefix, PATH23, POST, option).json(),
                $post: (option) => fetch(prefix, PATH23, POST, option).json().then(r => r.body)
            },
            hotelgroupplan: {
                list: {
                    post: (option) => fetch(prefix, PATH28, POST, option).json(),
                    $post: (option) => fetch(prefix, PATH28, POST, option).json().then(r => r.body)
                }
            },
            login: {
                charger: {
                    get: (option) => fetch(prefix, PATH29, GET, option).json(),
                    $get: (option) => fetch(prefix, PATH29, GET, option).json().then(r => r.body)
                }
            },
            onetimecharger: {
                post: (option) => fetch(prefix, PATH30, POST, option).json(),
                $post: (option) => fetch(prefix, PATH30, POST, option).json().then(r => r.body)
            },
            onetimelogin: {
                post: (option) => fetch(prefix, PATH31, POST, option).json(),
                $post: (option) => fetch(prefix, PATH31, POST, option).json().then(r => r.body)
            },
            onetimeloginc: {
                post: (option) => fetch(prefix, PATH32, POST, option).json(),
                $post: (option) => fetch(prefix, PATH32, POST, option).json().then(r => r.body)
            },
            onetimelogink: {
                post: (option) => fetch(prefix, PATH33, POST, option).json(),
                $post: (option) => fetch(prefix, PATH33, POST, option).json().then(r => r.body)
            },
            onetimemail: {
                post: (option) => fetch(prefix, PATH34, POST, option).json(),
                $post: (option) => fetch(prefix, PATH34, POST, option).json().then(r => r.body)
            },
            onetimename: {
                post: (option) => fetch(prefix, PATH35, POST, option).json(),
                $post: (option) => fetch(prefix, PATH35, POST, option).json().then(r => r.body)
            },
            onetimepass: {
                post: (option) => fetch(prefix, PATH36, POST, option).json(),
                $post: (option) => fetch(prefix, PATH36, POST, option).json().then(r => r.body)
            },
            onetimepasscust: {
                post: (option) => fetch(prefix, PATH37, POST, option).json(),
                $post: (option) => fetch(prefix, PATH37, POST, option).json().then(r => r.body)
            },
            onetimepassforgety: {
                post: (option) => fetch(prefix, PATH38, POST, option).json(),
                $post: (option) => fetch(prefix, PATH38, POST, option).json().then(r => r.body)
            },
            onetimesendaccesskey: {
                post: (option) => fetch(prefix, PATH39, POST, option).json(),
                $post: (option) => fetch(prefix, PATH39, POST, option).json().then(r => r.body)
            },
            onetimesendhg: {
                post: (option) => fetch(prefix, PATH40, POST, option).json(),
                $post: (option) => fetch(prefix, PATH40, POST, option).json().then(r => r.body)
            },
            roomclassinfo: {
                del: {
                    delete: (option) => fetch(prefix, PATH42, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, PATH42, DELETE, option).send().then(r => r.body)
                },
                ins: {
                    put: (option) => fetch(prefix, PATH43, PUT, option).json(),
                    $put: (option) => fetch(prefix, PATH43, PUT, option).json().then(r => r.body)
                },
                list: {
                    post: (option) => fetch(prefix, PATH44, POST, option).json(),
                    $post: (option) => fetch(prefix, PATH44, POST, option).json().then(r => r.body)
                },
                upd: {
                    post: (option) => fetch(prefix, PATH45, POST, option).send(),
                    $post: (option) => fetch(prefix, PATH45, POST, option).send().then(r => r.body)
                },
                post: (option) => fetch(prefix, PATH41, POST, option).json(),
                $post: (option) => fetch(prefix, PATH41, POST, option).json().then(r => r.body)
            },
            test: {
                get: (option) => fetch(prefix, PATH46, GET, option).json(),
                $get: (option) => fetch(prefix, PATH46, GET, option).json().then(r => r.body)
            },
            toursearch: {
                list: {
                    post: (option) => fetch(prefix, PATH47, POST, option).json(),
                    $post: (option) => fetch(prefix, PATH47, POST, option).json().then(r => r.body)
                }
            }
        }
    };
};
export default api;
