import _ from 'lodash';
import Jaco from 'jaco';
import { replace } from './string';
/**
 * 変換テーブル
 */
const MapTable = {
    able: {
        JaToRoma: {
            "キャ": `kya`,
            "キュ": `kyu`,
            "キョ": `kyo`,
            "シャ": `sya`,
            "シュ": `syu`,
            "シェ": `sye`,
            "ショ": `syo`,
            "チャ": `cha`,
            "チュ": `chu`,
            "チェ": `che`,
            "チョ": `cho`,
            "ニャ": `nya`,
            "ニュ": `nyu`,
            "ニェ": `nye`,
            "ニョ": `nyo`,
            "ヒャ": `hya`,
            "ヒュ": `hyu`,
            "ヒョ": `hyo`,
            "ファ": `fa`,
            "フィ": `fi`,
            "フュ": `fyu`,
            "フェ": `fe`,
            "フォ": `fo`,
            "ミャ": `mya`,
            "ミュ": `myu`,
            "ミョ": `myo`,
            "リャ": `rya`,
            "リュ": `ryu`,
            "リョ": `ryo`,
            "ギャ": `gya`,
            "ギュ": `gyu`,
            "ギョ": `gyo`,
            "ジャ": `zya`,
            "ジュ": `zyu`,
            "ジェ": `zye`,
            "ジョ": `zyo`,
            "ヂャ": `dya`,
            "ヂュ": `dyu`,
            "ヂョ": `dyo`,
            "ビャ": `bya`,
            "ビュ": `byu`,
            "ビョ": `byo`,
            "ピャ": `pya`,
            "ピュ": `pyu`,
            "ピョ": `pyo`,
            "ヴァ": `va`,
            "ヴィ": `vi`,
            "ヴェ": `ve`,
            "ヴォ": `vo`,
            "ガ": `ga`,
            "ギ": `gi`,
            "グ": `gu`,
            "ゲ": `ge`,
            "ゴ": `go`,
            "ザ": `za`,
            "ジ": `zi`,
            "ズ": `zu`,
            "ゼ": `ze`,
            "ゾ": `zo`,
            "ダ": `da`,
            "ヂ": `di`,
            "ヅ": `du`,
            "デ": `de`,
            "ド": `do`,
            "バ": `ba`,
            "ビ": `bi`,
            "ブ": `bu`,
            "ベ": `be`,
            "ボ": `bo`,
            "パ": `pa`,
            "ピ": `pi`,
            "プ": `pu`,
            "ペ": `pe`,
            "ポ": `po`,
            "ア": `a`,
            "イ": `i`,
            "ウ": `u`,
            "エ": `e`,
            "オ": `o`,
            "カ": `ka`,
            "キ": `ki`,
            "ク": `ku`,
            "ケ": `ke`,
            "コ": `ko`,
            "サ": `sa`,
            "シ": `si`,
            "ス": `su`,
            "セ": `se`,
            "ソ": `so`,
            "タ": `ta`,
            "チ": `ti`,
            "ツ": `tu`,
            "テ": `te`,
            "ト": `to`,
            "ナ": `na`,
            "ニ": `ni`,
            "ヌ": `nu`,
            "ネ": `ne`,
            "ノ": `no`,
            "ハ": `ha`,
            "ヒ": `hi`,
            "フ": `hu`,
            "ヘ": `he`,
            "ホ": `ho`,
            "マ": `ma`,
            "ミ": `mi`,
            "ム": `mu`,
            "メ": `me`,
            "モ": `mo`,
            "ヤ": `ya`,
            "ユ": `yu`,
            "ヨ": `yo`,
            "ラ": `ra`,
            "リ": `ri`,
            "ル": `ru`,
            "レ": `re`,
            "ロ": `ro`,
            "ワ": `wa`,
            "ヰ": `wi`,
            "ヱ": `we`,
            "ヲ": `wo`,
            "ァ": `a`,
            "ィ": `i`,
            "ゥ": `u`,
            "ェ": `e`,
            "ォ": `o`,
            "ャ": `ya`,
            "ュ": `yu`,
            "ョ": `yo`,
            "ヴ": `vu`,
            "(ー|ｰ)": ``,
            "[ッｯ]([a-z])": `$1$1`,
            cch: `tch`,
            "[ンﾝ]": `nn`,
            "n([bpm])": `m$1`,
            uu: `u`,
        },
        RomaToJa: {
            "m([bpm])": `ン$1`,
            nnnn: `ッn`,
            tch: `ッch`,
            kya: `キャ`,
            kyu: `キュ`,
            kyo: `キョ`,
            sha: `シャ`,
            shu: `シュ`,
            she: `シェ`,
            sho: `ショ`,
            cha: `チャ`,
            chu: `チュ`,
            cge: `チェ`,
            cho: `チョ`,
            nya: `ニャ`,
            nyu: `ニュ`,
            nye: `ニェ`,
            nyo: `ニョ`,
            hya: `ヒャ`,
            hyu: `ヒュ`,
            hyo: `ヒョ`,
            mya: `ミャ`,
            myu: `ミュ`,
            myo: `ミョ`,
            rya: `リャ`,
            ryu: `リュ`,
            ryo: `リョ`,
            gya: `ギャ`,
            gyu: `ギュ`,
            gyo: `ギョ`,
            zya: `ジャ`,
            zyu: `ジュ`,
            zye: `ジェ`,
            zyo: `ジョ`,
            dya: `ヂャ`,
            dyu: `ヂュ`,
            dyo: `ヂョ`,
            bya: `ビャ`,
            byu: `ビュ`,
            byo: `ビョ`,
            pya: `ピャ`,
            pyu: `ピュ`,
            pyo: `ピョ`,
            fyu: `フュ`,
            bb: `ッb`,
            cc: `ッc`,
            dd: `ッd`,
            ff: `ッf`,
            gg: `ッg`,
            hh: `ッh`,
            jj: `ッj`,
            kk: `ッk`,
            ll: `ッl`,
            mm: `ッm`,
            nn: `ン`,
            pp: `ッp`,
            qq: `ッq`,
            rr: `ッr`,
            ss: `ッs`,
            tt: `ッt`,
            vv: `ッv`,
            ww: `ッw`,
            xx: `ッx`,
            yy: `ッy`,
            zz: `ッz`,
            fa: `ファ`,
            fi: `フィ`,
            fe: `フェ`,
            fo: `フォ`,
            va: `ヴァ`,
            vi: `ヴィ`,
            vu: `ヴ`,
            ve: `ヴェ`,
            vo: `ヴォ`,
            ga: `ガ`,
            gi: `ギ`,
            gu: `グ`,
            ge: `ゲ`,
            go: `ゴ`,
            za: `ザ`,
            zi: `ジ`,
            zu: `ズ`,
            ze: `ゼ`,
            zo: `ゾ`,
            da: `ダ`,
            di: `ヂ`,
            du: `ヅ`,
            de: `デ`,
            do: `ド`,
            ba: `バ`,
            bi: `ビ`,
            bu: `ブ`,
            be: `ベ`,
            bo: `ボ`,
            pa: `パ`,
            pi: `ピ`,
            pu: `プ`,
            pe: `ペ`,
            po: `ポ`,
            ka: `カ`,
            ki: `キ`,
            ku: `ク`,
            ke: `ケ`,
            ko: `コ`,
            sa: `サ`,
            si: `シ`,
            su: `ス`,
            se: `セ`,
            so: `ソ`,
            ta: `タ`,
            ti: `チ`,
            tu: `ツ`,
            te: `テ`,
            to: `ト`,
            na: `ナ`,
            ni: `ニ`,
            nu: `ヌ`,
            ne: `ネ`,
            no: `ノ`,
            ha: `ハ`,
            hi: `ヒ`,
            hu: `フ`,
            he: `ヘ`,
            ho: `ホ`,
            ma: `マ`,
            mi: `ミ`,
            mu: `ム`,
            me: `メ`,
            mo: `モ`,
            ya: `ヤ`,
            yu: `ユ`,
            yo: `ヨ`,
            ra: `ラ`,
            ri: `リ`,
            ru: `ル`,
            re: `レ`,
            ro: `ロ`,
            wa: `ワ`,
            wi: `ヰ`,
            we: `ヱ`,
            wo: `ヲ`,
            a: `ア`,
            i: `イ`,
            u: `ウ`,
            e: `エ`,
            o: `オ`,
        }
    },
    axess: {
        JaToRoma: {
            "ジャ": `JA`,
            "ジュ": `JU`,
            "ジェ": `JE`,
            "ジョ": `JO`,
            "キャ": `KYA`,
            "キュ": `KYU`,
            "キョ": `KYO`,
            "ギャ": `GYA`,
            "ギュ": `GYU`,
            "ギョ": `GYO`,
            "シャ": `SHA`,
            "シュ": `SHU`,
            "シェ": `SHE`,
            "ショ": `SHO`,
            "チャ": `CHA`,
            "チュ": `CHU`,
            "チェ": `CHE`,
            "チョ": `CHO`,
            "ニャ": `NYA`,
            "ニュ": `NYU`,
            "ニェ": `NYE`,
            "ニョ": `NYO`,
            "ヒャ": `HYA`,
            "ヒュ": `HYU`,
            "ヒョ": `HYO`,
            "ビャ": `BYA`,
            "ビュ": `BYU`,
            "ビョ": `BYO`,
            "ピャ": `PYA`,
            "ピュ": `PYU`,
            "ピョ": `PYO`,
            "ミャ": `MYA`,
            "ミュ": `MYU`,
            "ミョ": `MYO`,
            "リャ": `RYA`,
            "リュ": `RYU`,
            "リョ": `RYO`,
            "ア": `A`,
            "イ": `I`,
            "ウ": `U`,
            "エ": `E`,
            "オ": `O`,
            "ポ": `PO`,
            "カ": `KA`,
            "キ": `KI`,
            "ク": `KU`,
            "ケ": `KE`,
            "コ": `KO`,
            "サ": `SA`,
            "ス": `SU`,
            "セ": `SE`,
            "ソ": `SO`,
            "タ": `TA`,
            "テ": `TE`,
            "ト": `TO`,
            "ナ": `NA`,
            "ニ": `NI`,
            "ヌ": `NU`,
            "ネ": `NE`,
            "ノ": `NO`,
            "ハ": `HA`,
            "ヒ": `HI`,
            "フ": `FU`,
            "ヘ": `HE`,
            "ホ": `HO`,
            "マ": `MA`,
            "ミ": `MI`,
            "ム": `MU`,
            "メ": `ME`,
            "モ": `MO`,
            "ヤ": `YA`,
            "ユ": `YU`,
            "ヨ": `YO`,
            "ラ": `RA`,
            "リ": `RI`,
            "ル": `RU`,
            "レ": `RE`,
            "ロ": `RO`,
            "ワ": `WA`,
            "ヲ": `WO`,
            "ン": `N-`,
            "ガ": `GA`,
            "ギ": `GI`,
            "グ": `GU`,
            "ゲ": `GE`,
            "ゴ": `GO`,
            "ザ": `ZA`,
            "ジ": `JI`,
            "ズ": `ZU`,
            "ゼ": `ZE`,
            "ゾ": `ZO`,
            "ダ": `DA`,
            "ヂ": `DI`,
            "ヅ": `DU`,
            "デ": `DE`,
            "ド": `DO`,
            "バ": `BA`,
            "ビ": `BI`,
            "ブ": `BU`,
            "ベ": `BE`,
            "ボ": `BO`,
            "パ": `PA`,
            "ピ": `PI`,
            "プ": `PU`,
            "ペ": `PE`,
            "シ": `SHI`,
            "チ": `CHI`,
            "ツ": `TSU`,
        },
        RomaToJa: {},
    },
};
/**
 * @type {JapaneseTypes.RomaMode}
 */
let convertRomaMode = `able`;
/**
 * ひらがなカナ(半角カナ)をローマ字に変換
 * @param {string} val
 */
function toRoma(val) {
    /** いったんカタカナにする(平仮名にヷヸヹヺがない)) */
    val = new Jaco(val)
        .toNarrow(false)
        .toKatakana(true)
        .toString();
    const table = _.get(MapTable, `${convertRomaMode}.JaToRoma`);
    let rep = replace(val);
    for (let u of table) {
        /** @type {string[]} */
        const [from, to] = [u, table[u]];
        rep = rep.replace(from, to);
    }
    let str = rep.value();
    str = (str || ``).toUpperCase();
    // Correct use of sokuon
    str = str.replace(/っC/g, `TC`).replace(/っ(.)/g, `$1$1`);
    str = str.replace(/ッC/g, `TC`).replace(/ッ(.)/g, `$1$1`);
    // Correct usage of N' (M' is a common mistake)
    str = str.replace(/[NM]'([^YAEIOU]|$)/g, `N$1`);
    // Correct use of choonpu
    str = str.replace(/Aー/g, `Ā`);
    str = str.replace(/Iー/g, `Ī`);
    str = str.replace(/Uー/g, `Ū`);
    str = str.replace(/Eー/g, `Ē`);
    str = str.replace(/Oー/g, `Ō`);
    if (convertRomaMode == `axess`) {
        /**
             *「ン」（N）のあとに「A」「I」「U」「E」「O」「ＹＡ」「ＹＵ」「ＹＯ」が来る場合、
             *「－」（ハイフン）を使用して区切ります
             */
        str = str.replace(/(N-)((?![AIUEO]|YA|YU|YO))+/g, `N`);
    }
    return str;
}
/**
 * ローマ字をカタカナに変換
 * @description オプションでひらがなに変換可能
 * @param {string} val
 * @param {boolean} [hiragana=false] ひらがなに変換する
 */
function fromRoma(val, hiragana = false) {
    const convertStr = romaToHiragana(val);
    if (hiragana)
        return toHiragana(convertStr); //!EXIT
    return convertStr;
}
/**
 * ローマ字をカタカナに変換
 * @param {string} val
 */
function romaToHiragana(val) {
    const table = _.get(MapTable, `${convertRomaMode}.RomaToJa`);
    let value = val.toLowerCase();
    for (let u of table)
        value = value.replace(new RegExp(u, `gi`), table[u]);
    return value;
}
/** カタカナをひらがなに変換する関数
 * @param {string} val - カタカナ
 * @returns {string} - ひらがな
 */
function toHiragana(val) {
    return new Jaco(val).toHiragana().toString();
}
/**
 * カタカナに変換する
 * @param {string} val
 */
function toKatakana(val) {
    return new Jaco(val).toKatakana(true).toString();
}
/**
 * 半角にする
 */
function toHan(val, options) {
    if (!val)
        return val; //!EXIT
    const option = _.extend({
        alphanum: true,
        mark: true,
        space: true,
        kanaOnly: false,
    }, options);
    let jaco = new Jaco(val);
    if (option.kanaOnly) {
        jaco.toNarrowKatakana();
    }
    else {
        if (option.alphanum)
            jaco = jaco.toNarrowAlphanumeric();
        if (option.mark)
            jaco = jaco.toNarrowSign();
        jaco = jaco.toNarrowKatakana();
    }
    return jaco.toString();
}
/**
 * 全角にする
 */
function toZen(val, options) {
    if (!val)
        return val; //!EXIT
    const option = _.extend({
        alphanum: false,
        mark: false,
        kana: true,
        kanaOnly: false,
    }, options);
    let jaco = new Jaco(val);
    if (option.kanaOnly) {
        jaco = jaco.toWideKatakana();
    }
    else {
        if (option.alphanum)
            jaco = jaco.toWideAlphanumeric();
        if (option.mark)
            jaco = jaco.toWideSign();
        if (option.kana)
            jaco = jaco.toWideJapanese();
    }
    return jaco.toString();
}
function setConvertMode(mode = `able`) {
    convertRomaMode = mode;
    return {
        toHiragana,
        toKatakana,
        toZen,
        toHan,
        toRoma,
        fromRoma,
        romaToHiragana,
    };
}
export { Jaco, toRoma, fromRoma, romaToHiragana, toHiragana, toKatakana, toHan, toZen, setConvertMode };
