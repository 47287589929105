import { defineComponent, reactive, ref, unref, computed, watch, onBeforeUnmount, onMounted } from '@vue/composition-api';
import apiHotel from '~/api/hotel/$api';
import { ENV } from "~/plugins/env";
import { ScrollLock } from "~/libs/eyes-lib/web";
import { arrayEmpty, booleanVal } from "~/libs/eyes-lib/basic";
import { toZen } from '~/libs/eyes-lib/japanese';
import { throttle } from 'lodash';
import NProgress from 'NProgress';
import _ from "lodash";
import axios from 'axios';
import aspida from '@aspida/axios';
/** 検索API */
const client = () => apiHotel(aspida(axios, {
    baseURL: ENV.API_HOTEL + `/v1`
}));
export default defineComponent({
    name: `hotel-search-form`,
    props: {
        caption: {
            type: String,
            required: false,
            default: ``
        },
        placeholder: {
            type: String,
            required: false,
            default: ``
        },
        minwidth: {
            type: String,
            required: false,
            default: `576px`
        },
        maxwidth: {
            type: String,
            required: false,
            default: `576px`
        },
        group: {
            type: String,
            required: false,
            default: `false`,
        },
    },
    setup(props, context) {
        const { $ } = window;
        // console.log(ENV.API_HOTEL, props)
        NProgress.configure({ showSpinner: false });
        /** 検索フォーム要素 */
        const keywordForm = ref();
        const $$app = ref();
        /** 状態 */
        const stat = reactive({
            overlay: false,
            focus: false,
            keyword: ``,
            items: [],
            group: booleanVal(props.group),
        });
        /** 関数 */
        const event = {
            isGroup: computed(() => stat.group),
            isOverlay: computed(() => stat.overlay),
            isFocus: computed(() => stat.focus),
            /** 検索結果がある場合 true */
            isResult: computed(() => stat.items.length > 0 && stat.focus),
            styles: computed(() => {
                return {
                    'width': stat.overlay ? props.maxwidth : props.minwidth,
                };
            }),
            focus: () => {
                stat.focus = true;
                stat.overlay = true;
            },
            cancel: () => {
                var _a;
                stat.focus = false;
                stat.overlay = false;
                (_a = unref(keywordForm)) === null || _a === void 0 ? void 0 : _a.blur();
                context.emit(`focus-event`, false);
            },
            selected: (item) => {
                context.emit(`selected-event`, item);
                location.href = `${ENV.BASE_URL_HOTEL}${ENV.CONTEXT_PATH}/detail/${item.hotelGroupCode}`;
            },
        };
        /**
         * キーワード変更イベント
         * @description
         * _.throttleで処理を間引いている。
         * `leading: false`により初回は実行しない。
         */
        const onChangeKeyword = throttle(async (keyword) => {
            /** 空文字の場合 ホテルグループリストを空で更新 */
            if (!keyword) {
                arrayEmpty(stat.items);
                NProgress.done();
                return;
            }
            const body = unref(event.isGroup) ? { hotelGroupName: keyword } : { hotelName: keyword };
            const res = await client().api.hotelgroup.list.post({ body })
                .then(res => _(res)
                .get(`body`)
                .map(item => {
                return Object.assign({}, item, {
                    hotelName: toZen(item.hotelName),
                    hotelGroupName: toZen(item.hotelGroupName),
                });
            }))
                .finally(() => NProgress.done());
            /** ホテルグループリストを更新 */
            if (res.length > 0) {
                arrayEmpty(stat.items, 0, ...res);
            }
            else {
                arrayEmpty(stat.items, 0, {
                    hotelName: `見つかりませんでした。`
                });
            }
        }, 600, { leading: false, trailing: true });
        const stopKeywordWatcher = watch(() => stat.keyword, keyword => {
            NProgress.inc();
            onChangeKeyword(keyword);
        });
        const stopOverlayWatcher = watch(() => stat.overlay, (newValue, oldValue) => {
            if (newValue) {
                /** 変更検知 */
                if (newValue != oldValue) {
                    const { $, modalResize } = window;
                    ScrollLock.on();
                    $(`.CenterLayer.ModalLayer.ActiveLayer`)
                        .hide()
                        .fadeIn({
                        step: modalResize,
                        always: modalResize,
                    });
                }
            }
            else {
                /** 変更検知 */
                if (newValue != oldValue) {
                    const { $ } = window;
                    ScrollLock.off();
                    $(`.CenterLayer.ModalLayer.ActiveLayer`)
                        .fadeOut()
                        .removeClass(`ActiveLayer`);
                }
            }
        });
        onMounted(() => {
            context.emit(`ready`);
            $(() => {
                const $dom = $$app.value = $(`#${context.root.$el.id}`);
                $dom.trigger(`ready`);
            });
        });
        onBeforeUnmount(() => {
            stopKeywordWatcher();
            stopOverlayWatcher();
        });
        return {
            props,
            stat,
            event,
            keywordForm,
        };
    },
});
