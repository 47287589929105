import { get, chain, hasIn, isArray, reduce, throttle, isNumber, isString, isElement, isBoolean, isDate, each } from 'lodash';
import { invoke, toInt } from './basic';
/**
 * スクロール位置設定と取得
 * @description jQuery使ってないけどlodashは使ってる
 * @param {string|HTMLElement|number} [val1]
 */
function scrollTop(val1) {
    if (!window || !document)
        return -1; //!EXIT
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = ((document.compatMode || ``) === `CSS1Compat`);
    const x = supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;
    const y = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
    if (isNumber(val1)) {
        window.scrollTo(x, val1);
        return val1;
    }
    /** 要素取得 */
    const elem = (() => {
        if (isString(val1))
            return document.getElementById(val1);
        else if (isNumber(val1))
            return null;
        else if (isElement(val1))
            return val1;
        else
            return null;
    })();
    if (elem) {
        /** 要素の座標を取得 */
        const rect = elem.getBoundingClientRect();
        /** スクロール量も考慮した要素の上部の位置 */
        return rect.top + y; //!EXIT
    }
    return y;
}
/**
 * キャンバスの複製
 * @param {HTMLCanvasElement} oldCanvas
 * @returns {HTMLCanvasElement}
 */
function cloneCanvas(oldCanvas) {
    //create a new canvas
    const newCanvas = document.createElement(`canvas`);
    const context = newCanvas.getContext(`2d`);
    //set dimensions
    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;
    //apply the old canvas to the new one
    context === null || context === void 0 ? void 0 : context.drawImage(oldCanvas, 0, 0);
    //return the new canvas
    return newCanvas;
}
/**
 * ページスクロールのロックと解除を担う
 * @description なるべくjQueryは使わないように
 */
const ScrollLock = {
    /** ページスクロールをロックする */
    on: () => {
        const scrollpos = scrollTop();
        const body = document.getElementsByTagName(`body`).item(0);
        if (body) {
            body.classList.add(`BlockScroll`);
            body.style.top = `-${scrollpos}px`;
            body.setAttribute(`style`, `top:-${scrollpos}px`);
        }
    },
    /** ページスクロールのロックを無効化 */
    off: () => {
        const body = document.getElementsByTagName(`body`).item(0);
        if (body) {
            const scrollpos = toInt(body.style.top);
            body.classList.remove(`BlockScroll`);
            body.style.top = `0px`;
            scrollTop(-scrollpos);
        }
    },
    /** ページスクロールがロックされているかどうか判定 */
    isLock: () => {
        let body = document.getElementsByTagName(`body`).item(0);
        return body === null || body === void 0 ? void 0 : body.classList.contains(`BlockScroll`);
    },
};
/**
 * イベント伝播停止
 * @param {Event|JQuery.Event} evt
 */
function eventStop(evt) {
    if (!evt)
        return false;
    invoke(evt, `stopImmediatePropagation`);
    invoke(evt, `preventDefault`);
    invoke(evt, `stopPropagation`);
    return false;
}
/**
 * 連打防止ユーティリティ
 * @param {(evt:Event, ...args: any[]) => any} action 連打防止させる関数
 * @param {{wait?:number, stop?:boolean}} [options]
 *  - wait: 連打無効ms
 *  - stop: イベント伝播停止フラグ
 * @example
 * $('.submit').on('click', Libs.actionGuard( evt => {
    *  ...
    * } ))
    */
function actionGuard(action, options) {
    /** デフォルトオプション */
    const opt = Object.assign({
        wait: 500,
        stop: true,
    }, options);
    return throttle(/** @param {Event} evt */ function (evt) {
        if (opt.stop)
            eventStop(evt);
        return action(evt);
    }, opt.wait);
}
/**
 * HTMLElement[]をHTMLCollection | NodeListOf<HTMLElement>に変換する
 * @description 主にVelocity.animateの型エラー対策用!?
 */
const toNodeList = function (item, context = document) {
    /** @type {NodeListOf<HTMLElement>} */
    let list = null;
    const elems = isArray(item) ? item : [item];
    if (null === context) {
        const docTemp = reduce(elems, (acc, elem) => {
            acc.appendChild(elem);
            return acc;
        }, document.createDocumentFragment());
        return docTemp.children; //!EXIT
    }
    chain(elems)
        .each(elem => elem.setAttribute(`wrapNodeList`, ``))
        .tap(elems => { list = context.querySelectorAll(`[wrapNodeList]`); })
        .each(elem => elem.removeAttribute(`wrapNodeList`))
        .commit();
    return list; //!EXIT
};
/**
 * スクロールオブジェクト
 * @description Chrome61以降userAgentだけでは判定できなくなっているので、document.scrollingElementを使う
 */
const scrollElem = (() => {
    if (typeof document === `undefined`)
        return; //!EXIT
    if (hasIn(document, `scrollingElement`))
        return get(document, `scrollingElement`); //モダンブラウザ
    else if (get(navigator, `userAgent`, ``).indexOf(`WebKit`) != -1)
        return get(document, `body`); //IE
    else
        return get(document, `documentElement`);
})();
/**
 * 正規表現に一致するテキストをタグではさむ関数
 * @description テキストをハイライト表示させたりする時
 * @param {Text} textNode
 * @param {string} tag
 * @param {number} begin
 * @param {number} end
 */
function wrapText(textNode, tag, begin, end) {
    const wrapped = document.createElement(tag);
    if (null == textNode || null == textNode.nodeValue || null == textNode.parentNode)
        return wrapped; //!EXIT
    // タグで挟む部分より前のテキストを新しいテキスト ノードにして textNode の前に挿入
    let fore = document.createTextNode(textNode.nodeValue.substring(0, begin));
    textNode.parentNode.insertBefore(fore, textNode);
    // タグで挟む部分を新しい要素にして textNode の前に挿入
    wrapped.appendChild(document.createTextNode(textNode.nodeValue.substring(begin, end)));
    textNode.parentNode.insertBefore(wrapped, textNode);
    // textNode の内容からタグで挟んだ部分までを削除
    textNode.nodeValue = textNode.nodeValue.substring(end);
    return wrapped;
}
/**
 * タグをNodeに挟み込む
 */
function wrapTag(element, regex) {
    const document = element.ownerDocument;
    const wraps = [];
    if (null == document)
        return wraps;
    const getNodes = function () {
        var _a, _b, _c, _d;
        let nodes = [], offset = 0, node, nodeIterator = document.createNodeIterator(element, NodeFilter.SHOW_TEXT, null);
        // eslint-disable-next-line no-cond-assign
        while (node = nodeIterator.nextNode()) {
            nodes.push({
                textNode: node,
                start: offset,
                length: (_b = (_a = node.nodeValue) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0
            });
            offset += (_d = (_c = node.nodeValue) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0;
        }
        return nodes;
    };
    let nodes = getNodes();
    if (!nodes.length)
        return wraps;
    let text = ``;
    for (let i = 0; i < nodes.length; ++i)
        text += nodes[i].textNode.nodeValue;
    let match;
    // eslint-disable-next-line no-cond-assign
    while (match = regex.exec(text)) {
        // Prevent empty matches causing infinite loops
        if (!match[0].length) {
            regex.lastIndex++;
            continue;
        }
        // Find the start and end text node
        let startNode;
        let endNode;
        for (let i = 0; i < nodes.length; ++i) {
            let node = nodes[i];
            if (node.start + node.length <= match.index)
                continue;
            if (!startNode)
                startNode = node;
            if (node.start + node.length >= match.index + match[0].length) {
                endNode = node;
                break;
            }
        }
        let range = document === null || document === void 0 ? void 0 : document.createRange();
        if (range) {
            range.setStart(startNode.textNode, match.index - startNode.start);
            range.setEnd(endNode.textNode, match.index + match[0].length - endNode.start);
            let spanNode = document === null || document === void 0 ? void 0 : document.createElement(`span`);
            if (spanNode) {
                spanNode.className = `eyes-seq`;
                spanNode.appendChild(range.extractContents());
                range.insertNode(spanNode);
                wraps.push(spanNode);
                nodes = getNodes();
            }
        }
    }
    return wraps;
}
/**
 * JSON送信用オブジェクトをURLSearchParams形式に変換
 * @param json 送信用オブジェクト
 */
function toURLSearchParams(json) {
    const params = new URLSearchParams();
    each(json, (v, k) => {
        if (isString(v) || isNumber(v) || isDate(v) || isBoolean(v))
            params.append(k, `${v}`);
    });
    return params;
}
export { actionGuard, cloneCanvas, eventStop, scrollElem, ScrollLock, scrollTop, toNodeList, wrapTag, wrapText, toURLSearchParams, };
