import { chain, defaultTo, each, filter, get, has, hasIn, isArray, isBoolean, isEmpty, isFunction, isNaN, isNil, isNull, isNumber, isPlainObject, isString, isUndefined, 
// map,
// merge,
// orderBy,
// reduce,
set, } from 'lodash';
// import deepMerge from 'deepmerge'
import EventEmitter from "wolfy87-eventemitter";
import Currencyjs from 'currency.js';
import * as uuid from 'uuid';
export { isArray, isBoolean, isEmpty, isFunction, isNaN, isNil, isNumber, isPlainObject, isString, };
/**
 * ### グローバルオブジェクトを取得する
 * @description クロージャを使ってシングルトンでグローバルオブジェクトを返すので気軽にコールできます
 * @return {Function}
 */
export const getGlobal = (function () {
    /**
     * グローバルオブジェクト
     * @type {Window} global
     * @description strict modeはFunctionコンストラクタまで伝搬せず、スコープ非依存でstrict modeの影響も受けない
     */
    const global = Function(`return this`)();
    /** node.js用に空オブジェクト登録しちゃう */
    if (!has(global, `window`))
        set(global, `window`, {});
    if (!has(global, `document`))
        set(global, `document`, {});
    if (!has(global, `navigator`))
        set(global, `navigator`, {});
    return function () {
        return global;
    };
})();
/**
 * null安全な関数コール
 * @param {*} target
 * @param {string} method
 * @param {*[]} args
 */
export function invoke(target, method, ...args) {
    if (hasIn(target, method) && isFunction(target[method])) {
        return target[method].apply(target, args);
    }
}
/**
 * NVL
 * @description 可変長引数対応
 * @template T
 * @type {(...args:any[])=>(T|'')}
 */
export function nvl(...args) {
    for (let i = 0, len = args.length; i < len; i++) {
        if (typeof args[i] === `string` && `${args[i]}`.trim() !== ``)
            return args[i];
        else if (typeof args[i] === `boolean`)
            return args[i];
        else if (typeof args[i] === `number`)
            return args[i];
        else if (typeof args[i] === `symbol`)
            return args[i];
        else if (typeof args[i] === `object` && args[i]) {
            return args[i];
        }
    }
    return ``;
}
export function noop(...args) {
    if (args.length === 1)
        return args[0];
    else
        return args;
}
/**
 * 指定した時間(ms)待つ
 * 第２引数があればその結果を返す
 */
export function timer(ms = 1000, callback) {
    return async (data) => {
        const timer = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        await timer(ms);
        if (isFunction(callback)) {
            const res = callback(data);
            if (isPromise(res)) {
                return await res; //! EXIT
            }
            return res; //! EXIT
        }
        return undefined == callback ? data : callback; //! EXIT
    };
}
export function sleep(ms = 0) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}
/**
 * 指定した関数の遅延実行
 */
export function lazy(func, ms = 0) {
    const ret = timer(ms, func);
    return isFunction(ret) ? ret() : ret;
}
/**
 * Promise判定
 */
export function isPromise(obj) {
    return !!obj
        && (typeof obj === `object` || typeof obj === `function`)
        && `then` in obj && typeof obj[`then`] === `function`
        && `catch` in obj && typeof obj[`catch`] === `function`
        && `finally` in obj && typeof obj[`finally`] === `function`;
}
/**
 * 変数名を取得する
 */
export function getVarName(val) {
    return Object.keys({ val })[0];
}
/**
 * グローバル空間に指定のキーで値を登録
 * @example //オブジェクトでまとめて登録も可
 * registToGlobal({$dom, fx, ModalQueue})
 */
export function registToGlobal(key, val, overwrite = false) {
    if (isEmpty(key))
        return false; //!EXIT
    if (typeof key === `string`) {
        if (overwrite) {
            set(getGlobal(), key, val);
            return true; //!EXIT
        }
        if (hasIn(getGlobal(), key)) {
            return false; //!EXIT
        }
        set(getGlobal(), key, val);
        return true; //!EXIT
    }
    else if (typeof key === `object`) {
        if (isBoolean(val))
            overwrite = val;
        each(key, (v, k) => {
            /** 上書きフラグtrueまたはglobalにまだない場合にセット */
            if (overwrite || !hasIn(getGlobal(), k))
                set(getGlobal(), k, v);
        });
    }
}
/**
 * 整数にする(小数点は切り捨て)
 * @description 不正な値は0で返す
 */
export function toInt(value, ...def) {
    var _a;
    try {
        /** 許さん型 */
        if (isNaN(value))
            throw new Error(); //!throw
        if (isPlainObject(value))
            throw new Error(); //!throw
        if (isFunction(value))
            throw new Error(); //!throw
        /** Booleanなら true:1 | false:0 で返す */
        if (isBoolean(value))
            return value ? 1 : 0;
        /** 整数に使われる文字列以外排除 */
        let val = defaultTo(`${value}`, ``).replace(/[^-+\d.]/g, ``);
        /** 先頭一文字確保 */
        let head = val.substr(0, 1);
        /** 先頭以外の-+を除去 */
        let tail = val.substr(1).replace(/-/g, ``);
        val = `${head}${tail}`;
        /** 1つ目のドットを探す */
        const dotIdx = val.indexOf(`.`);
        /** .は最初の1つ目以外除去 */
        if (dotIdx > -1) {
            const left = val.substr(0, dotIdx + 1);
            const right = val.substr(dotIdx);
            val = `${left}${right.replace(/\./g, ``)}`;
        }
        //10進数で数値化(小数点もparseIntで整数化)
        const i = parseInt(val, 10);
        //有効な数値かチェック
        if (!Number.isFinite(i))
            throw new Error(); //!throw
        return i; //!EXIT
    }
    catch (err) {
        if (!def)
            return 0; //!EXIT
        const value = (_a = def.shift()) !== null && _a !== void 0 ? _a : 0;
        const result = toInt(value, ...def);
        if (isNil(result) || isNaN(result))
            return 0; //!EXIT
        return result;
    }
}
/**
 * オブジェクト追加変更の差分抽出関数
 * @example
 * const srcObj = {a:1, b: 2, c: 3}
 * const updObj = {a:2, b: 2, d:4}
 * const diffObj = diff( srcObj, updObj )
 * diffObj -> Object { a: 2, d: 4 } //減った項目cは表現できないので注意
 */
export function diff(...objects) {
    const length = objects.length;
    const src = objects[0];
    const diffObj = {};
    const equal = (a, b) => a === b;
    let obj;
    let keys;
    let keysLength;
    let key;
    let u;
    for (let i = 1; i < length; i++) {
        obj = objects[i];
        keys = Object.keys(obj);
        keysLength = keys.length;
        for (u = 0; u < keysLength; u++) {
            key = keys[u];
            const objVal = get(obj, key);
            const srcVal = get(src, key);
            if (!equal(objVal, srcVal)) {
                set(diffObj, key, objVal);
            }
        }
    }
    return diffObj;
}
export function is(type, obj) {
    const clazz = Object.prototype.toString.call(obj).slice(8, -1);
    return clazz == type;
}
/**
 * DOMオブジェクトか判定する
 * @return {obj is HTMLElement}
 */
export function isElement(obj) {
    try {
        //Using W3 DOM2 (works for FF, Opera and Chrom)
        return obj instanceof HTMLElement;
    }
    catch (exp) {
        //Browsers not supporting W3 DOM2 don't have HTMLElement and
        //an exception is thrown and we end up here. Testing some
        //properties that all elements have. (works on IE7)
        return (typeof obj === `object`) &&
            (get(obj, `nodeType`) === 1) && (typeof get(obj, `style`) === `object`) &&
            (typeof get(obj, `ownerDocument`) === `object`);
    }
}
export function booleanVal(_val) {
    /** @type {(val:string)=>(boolean)} */
    const parseBoolean = function (val) {
        switch (`${val}`.trim().toLowerCase()) {
            case `true`:
            case `yes`:
            case `ok`:
            case `1`:
            case `up`:
            case `open`:
            case `good`:
            case `allow`:
            case `win`:
            case `success`:
            case `はい`:
            case `１`:
                return true;
            case `false`:
            case `no`:
            case `ng`:
            case `0`:
            case `down`:
            case `close`:
            case `bad`:
            case `deny`:
            case `lose`:
            case `fail`:
            case `いいえ`:
            case `０`:
            case `null`:
            case `undefined`:
                return false;
            default:
                return true;
        }
    };
    /** 文字列の場合 */
    if (typeof (_val) === `string`)
        return parseBoolean(_val);
    else if (_val instanceof String)
        return parseBoolean(_val.toString());
    else if (typeof (_val) === `boolean`)
        return _val;
    else
        return false;
}
/**
 * ファイル名を拡張子とそれ以外に分ける
 */
export function splitExt(value) {
    const val = `${defaultTo(value, ``)}`;
    return val.split(/\.(?=[^.]+$)/); //this.split(/(?=\.[^.]+$)/); ←はドットを含める場合
}
export function urlJoin(...values) {
    return values.map(val => val.replace(/^\/+|\/+$/g, ``)).join(`/`);
}
/**
 * 指定オブジェクトから複数のパスを指定し、最初に値が確認できるパスから値を取得する
 * @param {any} obj
 * @param  {..._.Many<string | number | symbol>} path
 */
export function foundPath(obj, ...path) {
    const falsy = Symbol();
    for (let idx = 0, len = path.length; idx < len; idx++) {
        const value = nvl(get(obj, path[idx]), falsy);
        if (falsy !== value)
            return value;
    }
    return undefined;
}
/**
 * 定義したオブジェクトとパスのセットから値が最初に確認できる値を取得する
 * @param  {...[any, _.Many<string | number | symbol>]} items
 */
export function foundValue(...items) {
    const falsy = Symbol();
    for (let idx = 0, len = items.length; idx < len; idx++) {
        const item = items[idx];
        const value = nvl(get(item[0], item[1]), falsy);
        if (falsy !== value)
            return value;
    }
    return undefined;
}
/**
 * プロパティ監視機能
 */
export class PropertyMonitor {
    constructor(obj) {
        this.emitter = new EventEmitter();
        this.obj = obj;
        if (isPlainObject(obj)) {
            for (let [key, value] of Object.entries(obj)) {
                Object.defineProperty(this, key, this.createDescriptor(key, value));
            }
        }
    }
    watch(event, callback) {
        this.emitter.on(event, callback);
        return this;
    }
    createDescriptor(key, value) {
        const self = this;
        return {
            get: function () {
                return value;
            },
            set: function (newVal) {
                //変化なしなら何もしない
                if (value === newVal)
                    return;
                value = newVal;
                self.emitter.emit(`change`, {
                    key: key,
                    value: newVal,
                });
            },
            enumerable: true,
            configurable: true
        };
    }
}
/**
 * URLパラメータ文字列の出力・解析
 */
export const QueryString = Object.freeze({
    /**
     * URLパラメータをオブジェクトにする
     * @param {string} text
     * @param {string} [sep="&"]
     * @param {string} [eq="="]
     * @param {Function | false} [decode] false またはデコード関数
     * @returns {Object.<string, string|number|boolean>}
     */
    parse: function (text = location.search, sep = `&`, eq = `=`, decode = decodeURIComponent) {
        /** デコード指定がfalseなら、加工しない関数を代入 */
        const decodeFunc = (false === decode) ? noop : decode;
        /** sep(&) で split した配列を reduce で回した結果を返す */
        return text
            .replace(/^(\?|#)?/, ``)
            .split(sep)
            .reduce(function (acc, v) {
            /** eq(=) で split して一番目を key 二番目を value として obj に代入 */
            const pair = v.split(eq);
            if (pair.length > 0 && pair[0]) {
                const [key, value] = pair;
                /** すでにキーがあるならば */
                if (has(acc, key)) {
                    /** 配列として値を追加 */
                    let vals = get(acc, key);
                    if (!isArray(vals)) {
                        vals = [vals];
                    }
                    vals.push(decodeFunc(value));
                    set(acc, key, vals);
                }
                else {
                    set(acc, key, decodeFunc(value));
                }
            }
            return acc;
        }, {});
    },
    /**
     * key-valueオブジェクトをURLパラメータ文字列に変換する
     * @param {Object} obj
     * @param {string} [sep="&"]
     * @param {string} [eq="="]
     * @param {Function|false} [encode=encodeURIComponent]
     * @returns {string}
     */
    stringify: function (obj, sep = `&`, eq = `=`, encode = encodeURIComponent) {
        /** エンコード指定がfalseなら、加工しない関数を代入 */
        const encodeFunc = (false === encode) ? noop : encode;
        /** Object.keys で key 配列を取得し, それを map で回した結果を sep(&) で join して返す */
        return chain(obj)
            .map(function (val, key) {
            /** キーが数値なのは無視 */
            if (isNumber(key))
                return ``; //!EXIT
            /** 値が配列の場合 */
            if (isArray(val)) {
                return val
                    .map(val => `${key}${eq}${encodeFunc(val)}`)
                    .join(sep);
                //!EXIT
            }
            /** key, value を eq(=) で連結した文字列を返す*/
            return `${key}${eq}${encodeFunc(val.toString())}`; //!EXIT
        })
            .compact()
            .join(sep)
            .value();
    },
});
/**
 * URLの解析情報を返す
 * @param { string | Location | HTMLAnchorElement } loc URL文字列か、locationかAタグ
 */
export function urlInfo(loc = location) {
    /**
     * 文字列の場合はHTMLAnchorElementにしてしまう
     * @type {Location | HTMLAnchorElement}
     */
    const locationInfo = isString(loc) ?
        (() => {
            /** HTMLAnchorElementにhrefをセットするとDOMにURL解析を任せる事ができる */
            const a = document.createElement(`a`);
            a.setAttribute(`href`, loc);
            return a;
        })() : loc;
    /** URLパラメータをオブジェクト化 */
    const param = QueryString.parse(locationInfo.search);
    /** 0番目として全体文字列を保管 */
    set(param, `0`, locationInfo.search);
    /** URLハッシュをオブジェクト化 */
    const hash = QueryString.parse(locationInfo.hash);
    /** 0番目として全体文字列を保管 */
    set(hash, `0`, locationInfo.hash);
    return chain(locationInfo)
        /** 必ずプレーンオブジェクト化 */
        .toPlainObject()
        .set(`param`, param)
        .set(`hash`, hash)
        /** 必要なプロパティのみ抽出 */
        .pick(`hash`, `param`, `host`, `hostname`, `href`, `pathname`, `port`, `protocol`, `search`)
        .tap(obj => {
        /** 必ず先頭は/をつけるように置換（IEが曖昧な挙動をしたため） */
        obj.pathname = obj.pathname.replace(/^(?!\/)/, `/`);
    })
        .value();
}
/**
 *
 */
export class PagerItem {
    constructor(param) {
        this.value = param.value;
        this.current = param.current;
        this.first = param.first;
        this.last = param.last;
        this.prev = param.prev;
        this.next = param.next;
        this.total = param.total;
    }
    isCurrent() {
        return this.current;
    }
    isFirst() {
        return this.first;
    }
    isLast() {
        return this.last;
    }
    isPrev() {
        return this.prev;
    }
    isNext() {
        return this.next;
    }
}
/**
 * ページネーション用イテレータ
 */
export class Pager {
    /**
     * @param {{pages:(string|number)[], scope:(string|number)[], target:string|number}} 全体ページデータ
     */
    constructor({ pages, scope, target }) {
        this.pages = pages;
        this.scope = scope;
        this.target = target;
    }
    /** イテレータ */
    *it() {
        const pages = this.pages;
        const total = pages.length;
        const scope = this.scope;
        // const hitIdx = scope.findIndex( val => this.target === val )
        for (let idx = 0, len = scope.length; idx < len; idx++) {
            /** 現在ページの値 */
            const value = scope[idx];
            // /** scope上のインデックス値 */
            const pageIdx = pages.indexOf(value);
            /** 選択indexかどうか */
            const current = value === this.target;
            const first = pageIdx === 0;
            const last = pageIdx + 1 === total;
            const prev = pageIdx > 0;
            const next = pageIdx + 1 < total;
            yield new PagerItem({
                value,
                current,
                first,
                last,
                prev,
                next,
                total,
            });
        }
    }
}
/**
 * 数値と文字コードの範囲(a-b)を使ったユーティリティ
 * @param {number|string} min 以上
 * @param {number|string} max 未満
 */
export function range({ min, max }) {
    return Object.freeze({
        /**
         * 引数の数値が含まれているか
         */
        include: function (val) {
            return min <= val && max >= val;
        },
        /**
         * 引数の数値が含まれていないか
         */
        exclude: function (val) {
            return min > val || max < val;
        },
        /**
         * 範囲内の乱数を生成
         * @param {number} digits
         */
        random: function (digits = 0) {
            if (isNumber(max) && isNumber(min))
                return (Math.random() * (max - min) + min).toFixed(digits);
        },
        /**
         * 第一引数の値を中心に、N件の範囲で抽出
         * @description 主にページャー用のユーティリティ
         * @param {number| string | {target:number,size:number}} _target 中心とする値
         * @param {number} [_size=5] 抽出サイズ
         */
        pager: function (_target, _size = 5) {
            /** 引数 */
            const { target, size } = isNumber(_target) || isString(_target)
                ? {
                    target: _target,
                    size: _size,
                }
                : _target;
            /** 対象配列 */
            /** @type {(string|number)[]} */
            const pages = range({ min, max }).toArray();
            const total = pages.length;
            const displaySize = total < size ? total : size;
            const centerIdx = Math.floor(displaySize / 2);
            const left = centerIdx;
            const right = displaySize - 1 - left;
            /** 対象配列から検索し、インデックス値を取得 */
            const hitIdx = pages.indexOf(target);
            if (-1 === hitIdx)
                return new Pager({
                    pages: [],
                    scope: [],
                    target: undefined,
                }); //!EXIT
            const _start = hitIdx - left;
            const _end = hitIdx + right;
            const overlyEnd = Math.max(_end - total + 1, 0);
            const overlyStart = Math.max(_start * -1, 0);
            let start = Math.max(0, overlyEnd > 0 ? _start - overlyEnd : _start);
            let end = Math.min(total, overlyStart > 0 ? _end + overlyStart : _end);
            const scope = pages.slice(Math.max(start, 0), end + 1);
            return new Pager({ pages, scope, target });
        },
        /**
         * 第一引数の値を中心に、N件の範囲で抽出
         * @description 主にページャー用のユーティリティ
         * @param {number| string | {target:number,size:number}} _target 中心とする値
         * @param {number} [_size=5] 抽出サイズ
         */
        center: function (_target, _size = 5) {
            return this.pager(_target, _size).scope;
        },
        /**
         * 範囲のリストを取得
         * @description 他のライブラリ(lodash)のrangeとは挙動が少し違います。
         * range(1,10).toArray() >> [1,2,3,4,5,6,7,8,9,10]
         * lodashだと_.range(1,10) >> [1,2,3,4,5,6,7,8,9]
         * @param {number?} step ステップ単位
         * @example range(1,100).toArray();
         * range('A','Z').toArray();
         */
        toArray: function (step = 1) {
            if (isNumber(min) && isNumber(max)) {
                const length = Math.max(Math.ceil((Math.max(min, max) - Math.min(min, max) + 1) / step), 0);
                const arr = Array(length);
                if (min > max) {
                    /** 逆順リスト作成 */
                    for (let idx = 0; idx < length; idx++, max -= step) {
                        arr[idx] = max;
                    }
                }
                else {
                    for (let idx = 0; idx < length; idx++, min += step) {
                        arr[idx] = min;
                    }
                }
                return arr; //!EXIT
            }
            else {
                const first = String(min).charCodeAt(0);
                const last = String(max).charCodeAt(0);
                const arr = new Array();
                if (first > last) {
                    /** 逆順リスト作成 */
                    for (let idx = first; idx >= last; idx--) {
                        arr.push(String.fromCodePoint(idx));
                    }
                }
                else {
                    for (let idx = first; idx <= last; idx++) {
                        arr.push(String.fromCodePoint(idx));
                    }
                }
                return arr; //!EXIT
            }
        },
        /**
         * イテレーターを返す
         * @description ジェネレータを利用
         * @param {number} [step=1] ステップ単位
         */
        it: function* (step = 1) {
            if (step === 0 || !isNumber(step))
                throw new Error(`step is no number.`);
            if (isNumber(min) && isNumber(max)) {
                const isDesc = min > max;
                /** 逆順リスト作成 */
                if (isDesc) {
                    for (let idx = min; idx >= max; idx = idx - step) {
                        yield idx;
                    }
                    return; //!EXIT
                }
                else {
                    for (let idx = min; idx <= max; idx = idx + step) {
                        yield idx;
                    }
                    return; //!EXIT
                }
            }
            else {
                /** 文字コードでリスト作成 */
                const first = String(min).charCodeAt(0);
                const last = String(max).charCodeAt(0);
                if (first > last) {
                    /** 逆順リスト作成 */
                    for (let idx = first; idx >= last; idx = idx - step) {
                        yield String.fromCodePoint(idx);
                    }
                }
                else {
                    for (let idx = first; idx <= last; idx = idx + step) {
                        yield String.fromCodePoint(idx);
                    }
                }
                return; //!EXIT
            }
        },
    });
}
/**
 * 数値をカンマ区切り文字列に変換
 * @param {string|number} value
 * @param {string|boolean} [mark] 単位記号
 */
export function currency(value, mark = false) {
    /** 単位記号を出力するか判定 */
    const isOutputMark = false !== mark;
    const symbolMark = isString(mark) ? defaultTo(mark, `¥`) : `¥`; //ﾜﾚﾜﾚﾊ日本人ﾃﾞｽ
    /** Currency.jsのオプション定義 */
    const option = { symbol: symbolMark, precision: 0 };
    /** 単位記号が特定の文字列だった場合、後ろにくっつける */
    if (isString(mark) && /(円|원|元|圓|圆|YEN|WON|Yuan)/i.test(mark))
        Object.assign(option, { pattern: `#!` });
    /** Currency.jsで整形 */
    return Currencyjs(value, option).format(isOutputMark);
}
/**
 * ローカルホストかIPで判定する
 * @param {string} ipv4
 */
export function isLocalhost(ipv4) {
    return /127.0.0.1/.test(ipv4);
}
/**
 * LANのIPなのか判定する
 * @param {string} ipv4
 */
export function isLan(ipv4) {
    return /127.0.0.1/.test(ipv4) ||
        /(192.168|172.16).\d{1,3}.\d{1,3}/.test(ipv4);
}
/**
 * 32bitユニークIDを返す
 * @description uuidモジュールのv1で生成してハイフンを削除(サイズ削り)
 */
export function uniqId() {
    return uuid.v1().split(`-`).join(``);
}
/**
 * Promiseを返す関数の配列を直列実行
 */
export function sequence(promises) {
    return promises.reduce(async (res, next) => {
        const r = await res;
        r.push(await next());
        return r;
    }, Promise.resolve([]));
}
/**
 * Promiseを返す関数の配列を並行実行
 * @template T
 * @param {Array<()=>Promise<T>>} promises
 */
export function parallel(promises) {
    return Promise.all(promises.map(p => p()));
}
/**
 * インデックスが数値のものだけを抽出し、配列で返す
 * @param {*} val
 */
export function convertArray(val) {
    return filter(val, (v, k) => {
        const indexValue = isString(k) ? parseInt(k, 10) : k;
        return isNumber(indexValue) && !isNaN(indexValue);
    });
}
export function arrayEmpty(target, head = 0, ...arr) {
    if (isArray(target)) {
        target.splice(head, target.length - head, ...arr);
    }
    else if (isNull(target) || isUndefined(target)) {
        if (arr)
            target = arr;
        else
            target = [];
    }
    return target;
}
export function objRecorder(obj) {
    return JSON.parse(JSON.stringify(obj));
}
export const queuePromise = async (promises, concurrency = 2) => {
    const results = [];
    let currentIndex = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        // console.log(Math.random())
        const chunks = promises.slice(currentIndex, currentIndex + concurrency);
        if (chunks.length === 0) {
            break;
        }
        Array.prototype.push.apply(results, await Promise.all(chunks.map(c => c())));
        currentIndex += concurrency;
    }
    return results;
};
/**
 * グループ分け:配列タプル返し
 * @param array ソース配列
 * @param getKey グループ分け基準
 */
export const groupTo = (array, getKey) => Array.from(array.reduce((map, cur, idx, src) => {
    const key = getKey(cur, idx, src);
    const list = map.get(key);
    if (list)
        list.push(cur);
    else
        map.set(key, [cur]);
    return map;
}, new Map()));
/**
 * グループ分け:オブジェクト返し
 * @param array ソース配列
 * @param getKey グループ分け基準
 */
export const groupObj = (array, getKey) => Object.fromEntries(groupTo(array, getKey));
/**
 *
 * @param array ソース配列
 * @param getIndex グループ分け基準
 * @example
 * const a = [1, 2, 3, 4];
 * const [even, odd] = scatter(a, x => x % 2);
 * console.log(even); // [2, 4]
 * console.log(odd);  // [1, 3]
 */
export const groupArray = (array, getIndex) => array.reduce((result, cur, idx, src) => {
    const i = getIndex(cur, idx, src) | 0;
    if (i >= 0)
        (result[i] || (result[i] = [])).push(cur);
    return result;
}, []);
