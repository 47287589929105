import _ from 'lodash';
import { toInt } from './basic';
export function trimStart(value, ...chars) {
    if (_.isString(value)) {
        chars = _.flatten(chars);
        chars = chars.length == 0 ? [` `, `　`, `\t`, `\r`, `\n`] : chars;
        return chars.reduce((acc, chr) => {
            if (acc.startsWith(chr)) {
                const val = acc.slice(chr.length);
                return trimStart(val, ...chars);
            }
            else
                return acc;
        }, value);
    }
    else if (_.hasIn(value, `href`)) {
        const href = _.get(value, `href`, ``);
        _.set(value, `href`, trimStart(href, ...chars));
        return value;
    }
    return `${value}`;
}
export function trimEnd(value, ...chars) {
    if (_.isString(value)) {
        chars = _.flatten(chars);
        chars = chars.length == 0 ? [` `, `　`, `\t`, `\r`, `\n`] : chars;
        return chars.reduce((acc, chr) => {
            if (acc.endsWith(chr)) {
                const t = acc.slice(0, -chr.length);
                return trimEnd(t, ...chars);
            }
            else
                return acc;
        }, value);
    }
    else if (_.hasIn(value, `href`)) {
        const href = _.get(value, `href`, ``);
        //@ts-ignore
        _.set(value, `href`, trimEnd(href, ...chars));
        return value;
    }
    return `${value}`;
}
export function trim(val, ...chars) {
    return trimStart.apply(null, [trimEnd.apply(null, [val, ...chars]), ...chars]);
}
/**
 * バイナリバッファから文字列に変換
 * @param {Buffer} buf
 */
export function bufferToString(buf) {
    return buf.toString(`utf-8`, 0, buf.length);
}
/**
 * 簡単なHTMLエスケープ
 * @description &#XXXX;
 * @param {string} value
 */
export function htmlEscape(value) {
    return _.defaultTo(value, ``).replace(/[&<>"']/g, m => `&#${m.charCodeAt(0)};`);
}
/**
 * HTMLアンエスケープ（タグに戻す）
 * @param {string} value
 */
export function htmlUnescape(value) {
    return _.defaultTo(value, ``).replace(/&#([a-fA-F0-9]+);/g, (matchedString, group1) => String.fromCharCode(parseInt(group1, 10)));
}
/**
 * 文字列からタグ除去
 * @param {string} value
 */
export function removeTag(value) {
    const val = `${_.defaultTo(value, ``)}`;
    const elem = document.createElement(`DIV`);
    elem.innerHTML = val;
    return elem.textContent ? elem.textContent : elem.innerText; //IEぇ…がinnerText
}
/**
 * ファイルサイズ容量表示
 * @param {number|string} value
 */
export function datasize(value) {
    const val = toInt(value, 0);
    const point = 2; // 小数点以下の何桁で表示するか
    const fs_unit = new Array(`GB`, `MB`, `KB`, `Byte`);
    for (let i = 0, j = 1073741824, len = fs_unit.length; i < len; i++, j /= 1024) {
        if (val >= j) {
            return Math.floor(val * Math.pow(10, point) / j) / Math.pow(10, point) + fs_unit[i];
        }
    }
    return `${val}Byte`;
}
/**
 * 文字列リテラルのインデントが可能になります（Scala風）
 * @param {TemplateStringsArray} template
 * @param {string[]} expressions
 */
export function TXT(template, ...expressions) {
    /** @type {string} */
    const result = template.reduce((accumulator, part, i) => {
        return accumulator + expressions[i - 1] + part;
    });
    return result.replace(/\r?(\n)\s*\|/g, `$1`);
}
/**
 * 一回ポッキリ置換
 * @description 複数の文字列を置換する際に置換後の文字を再度置換してしまう可能性を回避したい用
 * @param {string} base 置換元文字列
 */
export function replace(base) {
    /**
       * キャッシュ化
       * @type {string}
       */
    let memo = null;
    /** @type {ReplaceTypes.Dictionary} */
    const dict = [];
    /** メソッドチェーン */
    const method = {
        /**
             * 置換したい文字を設定する
             * @description 追加された場合はキャッシュクリア
             * @param {ReplaceTypes.From} from
             * @param {ReplaceTypes.To} [to]
             */
        replace: (from, to) => {
            if (_.isArray(from)) {
                let fromDict = from;
                if (_.isArray(to)) {
                    //@ts-ignore
                    const pairList = _.zip(from, to);
                    fromDict = _.map(pairList, pair => {
                        return {
                            from: pair[0],
                            to: pair[1],
                        };
                    });
                }
                /** dictに結合 */
                Array.prototype.push.apply(dict, fromDict);
                memo = null;
            }
            else if (_.isString(from) && _.isString(to)) {
                dict.push({ from, to });
                memo = null;
            }
            else if (_.isObject(from)) {
                dict.push(from);
                memo = null;
            }
            return method;
        },
        /**
             * 置換結果を返す
             * @description 評価済みならばキャッシュ返す
             */
        value: () => {
            /** メモ化済みならメモリキャッシュ返す */
            if (memo)
                return memo; //! EIXT
            /** １回ぽっきり置換用中間文字列 */
            const temp = _.reduce(dict, (acc, dict, idx) => {
                /** 人気のない＆ありえ難い組み合わせの文字で挟んで置換 */
                const reg = new RegExp(`${dict.from}`, `g`);
                return acc.replace(reg, `㌶${idx}纛`);
            }, base);
            /** 置換用中間文字列を */
            const res = _.reduce(dict, (acc, dict, idx) => {
                /** 中間結果を最終結果の文字に置換していく */
                const reg = new RegExp(`㌶${idx}纛`, `g`);
                return acc.replace(reg, `${dict.to}`);
            }, temp);
            /** メモ化 */
            memo = res;
            return res;
        }
    };
    return method;
}
